type AppGuidanceType = {
    screenName: string;
    moreViewValue?: string | null;
};

/**
 * 앱 온보딩 모달의 이벤트 프로퍼티를 반환합니다.
 */
const getAppGuidanceEventProperties = ({ screenName, moreViewValue }: AppGuidanceType) => {
    return {
        screen_name: screenName,
        more_view_value: moreViewValue || null,
    };
};

export default getAppGuidanceEventProperties;
