import { useState, useCallback } from 'react';
import useSyncedLocalStorage from '@tving/utils/src/hooks/common/useSyncedLocalStorage';
import dayjs from 'dayjs';
import usePopupExposureManager from '@stores/usePopupExposureManager';
import { shallow } from 'zustand/shallow';
import useIsomorphicLayoutEffect from '@tving/utils/src/hooks/common/useIsomorphicLayoutEffect';

const DEFAULT_POPUP_ID = '__default__';

/** 유효하지 않은 팝업을 정의하는 PopupId입니다. */
export const NULL_POPUP_ID = Symbol('__TVING_NULL_POPUP_ID__');

const isNullPopupId = (popupId: string | typeof NULL_POPUP_ID): popupId is typeof NULL_POPUP_ID => popupId === NULL_POPUP_ID || !popupId;

type PersistentPopupDisablementStore = {
    [popupId: string]: {
        /**
         * 'N일 동안 다시 보지 않기'를 선택한 경우 저장되는 만료일(UNIX Timestamp)
         */
        expiryUnixTime?: number;
        permanentlyDisabled?: boolean;
    };
};

type UsePersistentPopupControllerParams = {
    /**
     * 팝업 비활성화 상태를 저장할 LocalStorage의 키입니다.
     */
    storageId: string;
    /**
     * 팝업 스토리지 별로 하위 인스턴스를 구분하는 데 사용됩니다.
     * - 전달하지 않으면 기본값이 사용됩니다.
     * - 유효하지 않은 팝업을 정의할 때는 {@link NULL_POPUP_ID}를 사용하세요.
     */
    popupId?: string | typeof NULL_POPUP_ID;
    /**
     * 팝업을 본 적이 있는 경우, 다시 노출할 수 있는지 여부를 결정합니다. (타 앱으로 이동 또는 새로고침 시에는 초기화됩니다.)
     */
    canReopenPopupAfterSeen?: boolean;
};

type usePersistentPopupControllerResult = {
    /**
     * 팝업의 ID
     */
    id: string | typeof NULL_POPUP_ID;
    /**
     * 팝업이 비활성화되었는지 여부를 반환합니다. (열림 상태와 별개입니다.)
     */
    isPopupDisabled: boolean;
    /**
     * 팝업 비활성화 여부의 초기 값을 반환합니다. (마운트 시점의 값)
     */
    defaultIsPopupDisabled: boolean;
    /**
     * N일 동안 팝업을 노출하지 않도록 설정합니다.
     */
    disablePopupForNDays: (days: number) => void;
    /**
     * 팝업을 영구적으로 비활성화합니다.
     */
    disablePopupPermanently: () => void;
    /**
     * 해당 팝업을 이전에 본 적이 있는지 여부를 업데이트합니다.
     * - 팝업을 보았는지 여부는 정책에 따라 다를 수 있으므로 직접 제어합니다.
     * - 타 앱으로 이동하거나 새로고침 시 초기화됩니다.
     */
    updatePopupSeenStatus: (hasSeenBefore: boolean) => void;
    /**
     * ID 또는 정규 표현식을 받아 팝업 비활성화 상태를 초기화합니다.
     */
    clearPopupById: (targetIdOrPredicate: string | ((id: string) => boolean)) => void;
    /**
     * 현재 스토리지의 팝업 비활성화 상태를 초기화합니다.
     */
    clearAllPopups: () => void;
};

const usePersistentPopupController = ({
    storageId,
    popupId = DEFAULT_POPUP_ID,
    canReopenPopupAfterSeen = true,
}: UsePersistentPopupControllerParams): usePersistentPopupControllerResult => {
    if (!storageId) {
        throw new Error('usePersistentPopupController: storageId는 필수입니다.');
    }

    if (popupId === '') {
        throw new Error(
            'usePersistentPopupController: popupId는 빈 문자열이 될 수 없습니다. 기본값을 사용하려면 `undefined`를 전달하고, 유효하지 않은 팝업은 `NULL_POPUP_ID`를 사용하세요.',
        );
    }

    const storageKey = isNullPopupId(popupId) ? (NULL_POPUP_ID.description as string) : popupId;
    const popupIdToUse = isNullPopupId(popupId) ? NULL_POPUP_ID : popupId;

    const { hasSeenPopupBefore, _updatePopupSeenStatus, _updateMultiplePopupSeenStatus } = usePopupExposureManager(
        (state) => ({
            hasSeenPopupBefore: state?.storages?.[storageId]?.[storageKey]?.hasSeenBefore || false,
            _updatePopupSeenStatus: state.updatePopupSeenStatus,
            _updateMultiplePopupSeenStatus: state.updateMultiplePopupSeenStatus,
        }),
        shallow,
    );

    // 현재 날짜는 렌더링된 시점을 기준으로 판단합니다.
    const [today] = useState(dayjs());
    const [popupStorage, setPopupStorage] = useSyncedLocalStorage<PersistentPopupDisablementStore>(storageId, {});

    const permanentlyDisabled = popupStorage?.[storageKey]?.permanentlyDisabled || false;
    const expiryUnixTime = popupStorage?.[storageKey]?.expiryUnixTime || 0;

    const isPopupDisabled = (() => {
        // 유효하지 않은 Null 팝업은 항상 비활성화 상태로 처리합니다.
        if (isNullPopupId(popupId)) {
            return true;
        }

        if (permanentlyDisabled) {
            return true;
        }

        if (!canReopenPopupAfterSeen && hasSeenPopupBefore) {
            return true;
        }

        return today.unix() < expiryUnixTime;
    })();

    // popupId 업데이트 시점 초기값을 사용할 수 있도록 함
    const [defaultIsPopupDisabled, setDefaultIsPopupDisabled] = useState(isPopupDisabled);

    useIsomorphicLayoutEffect(() => {
        // 팝업 비활성화 기본값은 popupId를 기준으로 지정합니다.
        setDefaultIsPopupDisabled(isPopupDisabled);
    }, [popupId]);

    const disablePopupForNDays = useCallback(
        (days: number) => {
            if (isNullPopupId(popupId)) {
                return;
            }

            if (!Number.isInteger(days) || days < 0) {
                throw new Error('팝업 비노출 기간은 0일 이상 정수이어야 합니다.');
            }

            const nextExpiryUnixTime = dayjs().add(days, 'day').unix();
            setPopupStorage((prevPopupStorage) => ({
                ...prevPopupStorage,
                [storageKey]: {
                    expiryUnixTime: nextExpiryUnixTime,
                    permanentlyDisabled: false,
                },
            }));
        },
        [popupId, storageKey, setPopupStorage],
    );

    const disablePopupPermanently = useCallback(() => {
        if (isNullPopupId(popupId)) {
            return;
        }

        setPopupStorage((prevPopupStorage) => ({
            ...prevPopupStorage,
            [storageKey]: {
                expiryUnixTime: 0,
                permanentlyDisabled: true,
            },
        }));
    }, [popupId, storageKey, setPopupStorage]);

    const updatePopupSeenStatus = useCallback(
        (hasSeenBefore: boolean) => {
            if (isNullPopupId(popupId)) {
                return;
            }

            _updatePopupSeenStatus(storageId, storageKey, hasSeenBefore);
        },
        [popupId, storageId, storageKey, _updatePopupSeenStatus],
    );

    const clearPopupById = useCallback(
        (targetIdOrPredicate: string | ((id: string) => boolean)) => {
            const newStorage = { ...popupStorage };
            if (typeof targetIdOrPredicate === 'string') {
                delete newStorage[targetIdOrPredicate];
                _updatePopupSeenStatus(storageId, targetIdOrPredicate, false);
            } else if (typeof targetIdOrPredicate === 'function') {
                Object.keys(newStorage).forEach((key) => {
                    if (targetIdOrPredicate(key)) {
                        delete newStorage[key];
                        _updatePopupSeenStatus(storageId, key, false);
                    }
                });
            }

            setPopupStorage(newStorage);
        },
        [storageId, popupStorage, setPopupStorage, _updatePopupSeenStatus],
    );

    const clearAllPopups = useCallback(() => {
        _updateMultiplePopupSeenStatus(storageId, Object.keys(popupStorage || {}), false);
        setPopupStorage({});
    }, [storageId, popupStorage, setPopupStorage, _updateMultiplePopupSeenStatus]);

    return {
        id: popupIdToUse,
        isPopupDisabled,
        defaultIsPopupDisabled,
        disablePopupForNDays,
        disablePopupPermanently,
        updatePopupSeenStatus,
        clearPopupById,
        clearAllPopups,
    };
};

export default usePersistentPopupController;
