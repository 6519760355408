import BottomSheet from '@components/common/popupLayer/bottomSheet';
import usePersistentPopupController from '@hooks/usePersistentPopupController';
import useClientStore from '@stores/useClientStore';
import { Button } from '@tving/ui';
import useToggle from '@tving/utils/src/hooks/common/useToggle';
import { useOneLinkSmartScript } from '@tving/utils/src/utils/onelink-smart-script';
import { useEffect, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';
import amplitudeEvent from '@tving/utils/src/utils/amplitude/amplitudeEvent';
import getAppGuidanceEventProperties from '@utils/amplitude/eventProperties/getAppGuidanceEventProperties';
import { getScreenName, getMoreViewValue } from '@utils/items/utils';
import { useRouter } from 'next/router';
import { getBridgeDefaultTargetView } from './helper';

// 기획에 따라 페이지 로드 완료 후 2초 뒤에 앱 유도 모달이 노출됩니다.
const MODAL_OPEN_DELAY = 2000;

// 기획에 따라 모달을 닫은 후 1일 동안 재노출하지 않습니다.
const MODAL_DISABLEMENT_EXPIRY_DAYS = 1;

const MODAL_DISABLEMENT_STORAGE_ID = 'tving-app-guidance-modal-disablement';

/**
 * 모바일 웹에서 앱 호출을 유도하는 Bottom Sheet입니다.
 */
const AppGuidanceModal = () => {
    const { isWebview, isMobile } = useClientStore(
        (state) => ({
            isWebview: state.isWebview,
            isMobile: state.isMobile,
        }),
        shallow,
    );

    const { defaultIsPopupDisabled, disablePopupForNDays } = usePersistentPopupController({
        storageId: MODAL_DISABLEMENT_STORAGE_ID,
    });

    const [isOpen, { set: setIsOpen }] = useToggle(false);
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // 모달이 한 번이라도 열렸는지 여부
    const hasModalOpened = useRef(false);

    // 모바일 웹에서만 앱 유도 모달을 노출하며, 한 번 노출된 이후 일정 기간동안 재노출하지 않습니다.
    const shouldExposeAppGuidanceModal = isMobile && !isWebview && !defaultIsPopupDisabled;

    useEffect(
        () => {
            if (!shouldExposeAppGuidanceModal || isOpen || hasModalOpened.current) {
                return;
            }

            const timeoutId = setTimeout(() => {
                hasModalOpened.current = true;
                setIsOpen(true);
                disablePopupForNDays(MODAL_DISABLEMENT_EXPIRY_DAYS);
            }, MODAL_OPEN_DELAY);

            return () => {
                timeoutId && clearTimeout(timeoutId);
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps -- shouldExposeAppGuidanceModal, isOpen가 변경될 때만 실행되도록 의존성을 설정합니다. (최초 모달 노출 시점 트리거)
        [shouldExposeAppGuidanceModal],
    );

    return (
        <BottomSheet
            isOpen={isOpen}
            onClose={handleCloseModal}
            enableBodyContentScroll={false}
            headerContent={null}
            bodyContent={<AppGuidanceModalBody closeModal={handleCloseModal} />}
        />
    );
};

type AppGuidanceModalBodyProps = {
    closeModal: () => void;
};

const AppGuidanceModalBody = ({ closeModal }: AppGuidanceModalBodyProps) => {
    const oneLinkSmartScript = useOneLinkSmartScript();
    const { pathname, query } = useRouter();

    const [oneLinkUrl] = useState(
        (() => {
            const defaultTargetView = getBridgeDefaultTargetView(pathname, query);

            return oneLinkSmartScript.generateOneLinkFromUrl(window.location.href, {
                fallbackAfDp: encodeURIComponent(defaultTargetView),
                fallbackDeepLinkValue: encodeURIComponent(defaultTargetView),
            });
        })(),
    );

    const handleOpenApp = () => {
        amplitudeEvent({
            event: 'click_app_play_mo',
            eventProperties: getAppGuidanceEventProperties({
                screenName: getScreenName({ pathName: pathname }),
                moreViewValue: getMoreViewValue({ pathname }),
            }),
        });

        window.location.href = oneLinkUrl;
    };

    const handleCloseModal = () => {
        amplitudeEvent({
            event: 'click_mo_play_mo',
            eventProperties: getAppGuidanceEventProperties({
                screenName: getScreenName({ pathName: pathname }),
                moreViewValue: getMoreViewValue({ pathname }),
            }),
        });

        closeModal();
    };

    useEffect(
        () => {
            oneLinkUrl && oneLinkSmartScript.fireImpressionsLink();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps -- oneLinkUrl가 변경될 때만 실행되도록 의존성을 설정합니다.
        [oneLinkUrl],
    );

    return (
        <div className="flex flex-col items-center max-w-[calc(35rem_+_1.33rem_*_2)] mx-auto pt-[2.33rem] pb-[1.33rem] px-[1.33rem]">
            {/* 삼성 브라우저에서 다크 모드로 Color Scheme을 임의로 변경합니다. 이 때, SVG의 경우 밝기 필터까지 강제로 조절하므로, 변형이 상대적으로 적게 발생하는 PNG를 사용합니다... :/ */}
            <img src="/img/icon-tving-app_240807.png" alt="" className="w-[4.333rem] h-[4.333rem] overflow-visible object-contain" />

            <div className="mt-[1rem] text-[1.667rem] text-white font-bold leading-[1.2] text-center">티빙을 더 편리하게 즐기는 방법</div>

            <Button
                tag="button"
                className="w-full px-[2rem] py-[1.17rem] mt-[1.67rem] bg-tint-red rounded-[0.333rem] text-[1.333rem] text-white font-bold leading-[1.25] text-center"
                onClick={handleOpenApp}
            >
                지금 바로 앱에서 시청하기
            </Button>
            <Button
                tag="button"
                className="pt-[0.75rem] pb-[0.83rem] px-[0.67rem] mt-[0.67rem] text-[1.167rem] text-gray-70 font-normal leading-[1.2] underline"
                onClick={handleCloseModal}
            >
                모바일 웹으로 볼게요
            </Button>
        </div>
    );
};

export default AppGuidanceModal;
