const DEFAULT_TARGET_VIEW = 'tvingapp://com.tving.home';

export const getBridgeDefaultTargetView = (pathname: string, query: Record<string, string | string[] | undefined>) => {
    if (pathname === '/contents/[mediaCode]' && typeof query.mediaCode === 'string') {
        return `tvingapp://play?cd=${query.mediaCode}`;
    }

    if (
        (pathname === '/more/band/[key]' ||
            pathname === '/more/curation/[key]' ||
            pathname === '/more/genre/[key]' ||
            pathname === '/more/special/[key]') &&
        typeof query.key === 'string'
    ) {
        let moreType: string = '';
        switch (true) {
            case pathname === '/more/band/[key]': {
                moreType = 'band';
                break;
            }
            case pathname === '/more/curation/[key]': {
                moreType = 'curation';
                break;
            }
            case pathname === '/more/genre/[key]': {
                moreType = 'genre';
                break;
            }
            case pathname === '/more/special/[key]': {
                moreType = 'special';
                break;
            }
            default: {
                break;
            }
        }

        return `tvingapp://com.tving.home/more/${moreType}/${query.key}`;
    }

    return DEFAULT_TARGET_VIEW;
};
