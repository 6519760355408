import { create } from 'zustand';

type Popup = {
    hasSeenBefore: boolean;
};

type PopupExposureManagerStore = {
    storages: {
        [storageId: string]: {
            [popupId: string]: Popup;
        };
    };
    /**
     * '해당 팝업을 이전에 본 적이 있는지 여부'를 반환합니다.
     * - 팝업을 본 적이 있는지 여부는 메인 홈('/') 최초 진입을 기준으로 판단합니다.
     * - 회원/빌링 페이지를 거치는 경우 리다이렉트 또는 새로고침으로 정확히 판단할 수 없어, 본 적이 없는 것(false)으로 간주합니다. (ex. 프로필 선택, 이용권 구매 등)
     *
     * @see {@link https://tvingcorp.slack.com/archives/C05JKNHU5U2/p1703568840162689?thread_ts=1703066122.009879&cid=C05JKNHU5U2 Slack - 협의 내용}
     */
    hasSeenPopupBefore: (storageId: string, popupId?: string) => boolean;
    /**
     * 해당 팝업을 이전에 본 적이 있는지 여부를 업데이트합니다.
     */
    updatePopupSeenStatus: (storageId: string, popupId: string, hasSeenBefore: boolean) => void;
    /**
     * 해당 팝업들을 이전에 본 적이 있는지 여부를 업데이트합니다.
     */
    updateMultiplePopupSeenStatus: (storageId: string, popupIds: string[], hasSeenBefore: boolean) => void;
};

/**
 * 팝업의 노출 여부 정책에 대한 내부 구현체입니다. 직접 사용하지 말고 {@link [usePersistentPopupController](../hooks/usePersistentPopupController.ts)}를 통해 사용해야 합니다.
 */
const usePopupExposureManager = create<PopupExposureManagerStore>()((set, get) => ({
    storages: {},
    hasSeenPopupBefore: (storageId, popupId) => {
        const { storages } = get();

        if (storageId && popupId && !!storages?.[storageId]?.[popupId]) {
            return storages?.[storageId]?.[popupId]?.hasSeenBefore || false;
        }

        return false;
    },
    updatePopupSeenStatus: (storageId, popupId, hasSeenBefore) => {
        const { storages } = get();

        if (!storageId || !popupId) {
            return;
        }

        set(() => ({
            storages: {
                ...(storages || null),
                [storageId]: {
                    ...(storages[storageId] || null),
                    [popupId]: {
                        ...(storages[storageId]?.[popupId] || null),
                        hasSeenBefore,
                    },
                },
            },
        }));
    },
    updateMultiplePopupSeenStatus: (storageId, popupIds, hasSeenBefore) => {
        const { storages } = get();

        if (!storageId || !popupIds.length) {
            return;
        }

        const updatedStorages = { ...(storages[storageId] || null) };

        popupIds.forEach((popupId) => {
            if (updatedStorages[popupId]) {
                updatedStorages[popupId].hasSeenBefore = hasSeenBefore;
            }
        });

        set(() => ({
            storages: {
                ...(storages || null),
                [storageId]: updatedStorages,
            },
        }));
    },
}));

export default usePopupExposureManager;
