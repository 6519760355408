import { URL_TVING } from '@constants/urls';
import { pageOrigin } from '@utils/apiConfig';
import Head from 'next/head';

type MetaProps = {
    isNoIndex?: boolean;
    title: string;
    description: string;
    keywords: string;
    link?: string;
    thumbnail: string;
    openGraph?: {
        description?: string;
        image?: string;
        url?: string;
        twitter?: {
            card?: string;
        };
    };
};

const Meta = ({
    isNoIndex = false,
    title,
    description,
    keywords,
    link = `${URL_TVING}`,
    thumbnail,
    openGraph = {
        description,
        image: 'https://image.tving.com/public_v7/portal/img/common/tving_log.jpg',
        url: link,
        twitter: {
            card: 'summary',
        },
    },
}: MetaProps) => {
    return (
        <Head>
            <title>{title}</title>
            {isNoIndex && <meta name="robots" content="noindex,nofollow" />}
            <link rel="canonical" href={link} />
            <link rel="alternate" href={pageOrigin} />
            <meta name="author" content="TVING" />
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="fb:app_id" content="167597706623778" />
            <meta property="og:site_name" content="TVING" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={thumbnail} />
            <meta property="og:image:width" content="800" />
            <meta property="og:image:height" content="400" />
            <meta property="og:url" content={link} />
            <meta property="og:locale" content="ko_KR" />
            <meta name="twitter:card" content={openGraph?.twitter?.card} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:url" content={link} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={thumbnail} />
        </Head>
    );
};

export default Meta;
