import { useMemo, useState } from 'react';

const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const apis = useMemo(
    () => ({
      toggle: () => setValue((prevValue) => !prevValue),
      set: (nextValue: boolean) => setValue(!!nextValue), // JS 파일에서 사용하는 경우도 있어, boolean으로 강제 형변환합니다. :)
    }),
    [],
  );

  return [value, apis] as const;
};

export default useToggle;
